<template>
  <v-card class="ma-0" :maxHeight="height" :minHeight="minHeight">
    <v-card-title class="secondary white--text py-0"> Текущие задачи ({{ data.length }}) </v-card-title>
    <v-divider />
    <v-card-text class="mt-0 pa-1 pt-0">
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <div v-if="data.length > 0">
        <v-simple-table fixed-header dense :height="height - 36">
          <template v-slot:default>
            <thead class="main-table-header">
              <tr>
                <th class="text-left px-1"></th>
                <th class="text-left px-1">Дата и время</th>
                <th class="text-left px-1">Заголовок</th>
                <th class="text-left px-1">Тип</th>
                <th class="text-left px-1">Кому</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(el, i) in dataSort" :key="i" @click="$emit('click', el)" style="cursor: pointer">
                <td class="text-left px-1">
                  <a-view-table-chip
                    :value="el.is_important"
                    :model="{
                      hideText: true,
                      showText: el.id,
                      options: $store.getters['config/get'].models.tasks.optionsIsImportant,
                    }"
                  />
                </td>
                <td class="text-left px-1">
                  <a-view-table-date :value="el.date_start" />
                </td>
                <td class="text-left px-1">
                  <v-chip x-small v-if="el.status == 0" color="error" class="px-1"> new </v-chip>
                  <a-view-table-taskname :value="el.name" :values="el" />
                </td>
                <td class="text-left px-1">
                  {{ getTextByValue(el.type, model) }}
                </td>
                <td class="text-left px-1">{{ el.user_name }}</td>
              </tr>
            </tbody>
          </template></v-simple-table
        >
      </div>
      <div v-else>Список пуст</div>
    </v-card-text>
  </v-card>
</template>

<script>
import { modelOptions } from "@/components/mixings";
export default {
  mixins: [modelOptions],

  components: {},
  props: {
    height: { type: Number, default: 0 },
    rows: Array,
  },
  data() {
    return {
      showTaskDialog: false,
      contentHeight: 10,
      model: { options: this.$store.getters["config/get"].models.tasks.types },

      idEdit: 0,
      loading: false,
      data: [],
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this.data.length == 0) {
        h = 0;
      }
      return h;
    },
    dataSort() {
      return this.data.multiSort(["date_start"]);
      return this.data.sort((a, b) => {
        return (b.status == 0) - (a.status == 0) || (a.date_start > b.date_start) - (a.date_start < b.date_start);
      });
    },
    filters() {
      const f = this.$store.getters["config/get"].config.taskToday;
      let res = Object.assign(f.other.filters, {
        date_end: {
          condition: ">=",
          value: new Date().date,
        },
      });
      return res;
    },
  },
  methods: {
    async fitchData() {
      this.loading = true;
      if (this.rows) {
        this.data = this.rows;
      } else {
        const filters = this.filters;

        this.data = (
          await this.$axios.get("/mechti/tasks", {
            params: {
              sort: { key: "date_start", order: "ASC" },
              filters,
            },
          })
        ).data.data;
      }
      this.loading = false;
    },
  },
};
</script>
